export const { mapping } = useBloomreachComponents()

export const setPageData = () => {
  const pageStore = usePageStore()
  const route = useRoute()

  pageStore.pageType = route.path.includes('/catalogsearch') ? 'SEARCH' : route.meta.page?.pageType || 'BLOOMREACH'
  pageStore.pageData = route.meta.page?.pageData
  pageStore.configuration = route.meta.page?.configuration
  pageStore.routeData = route.meta.routeData
  pageStore.hrefLangs = route.meta.page?.hrefLangs || []
  pageStore.bloomreachPage = route.meta.page?.bloomreachPage || []

  return {
    type: route.meta.type,
    page: route.meta.page?.bloomreachPage,
    path: route.meta.path,
  }
}

export default setPageData
