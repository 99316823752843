/* eslint-disable  */

export default {
  'Page {currentPage} of {totalPages}': 'Pagina {currentPage} van {totalPages}',
  'A 10% discount on your next purchase?': 'Wil je 10% korting op je volgende bestelling?',
  'A customer with the same email address already exists in an associated website.':
    'A customer with the same email address already exists in an associated website.',
  'About us': 'Over ons',
  Account: 'Account',
  'Account dashboard': 'Account dashboard',
  'Account Details': 'Accountgegevens',
  'Add new address': 'Nieuw adres toevoegen',
  'Add review': 'Review versturen',
  'Add the address': 'Adres toevoegen',
  'Add to cart': 'In winkelwagen',
  'Quick add to cart': 'In winkelwagen',
  Adding: 'Toevoegen',
  'Added!': 'Toegevoegd!',
  'Add to compare': 'Toevoegen om te vergelijken',
  'Add to Wishlist': 'Voeg toe aan wishlist',
  'Added to cart': 'Toegevoegd aan winkelwagen',
  'Additional Information': 'Aanvullende informatie',
  'Addresses details': 'Adresboek',
  'Adjusted opening hours': 'Openingstijden op feestdagen',
  'Allow order notifications': 'Meldingen over bestelling toestaan',
  and: 'en',
  Apply: 'Toepassen',
  'Attention!': 'Let op!',
  'Available again soon': 'Binnenkort weer beschikbaar',
  'Available from date': 'Vanaf {date} beschikbaar',
  'BACK SOON': 'BACK SOON',
  'Back to home': 'Terug naar home',
  'Back to homepage': 'Terug naar homepage',
  'Back to top': 'Terug naar boven',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Blijf op de hoogte van sale en events. Ontvang goodies en speciale aanbiedingen. ',
  'Your feedback means a lot to us: write a review!': 'Jouw feedback is waardevol: schrijf een review!',
  Billing: 'Factuur',
  'Billing address': 'Factuuradres',
  'Birthday incorrect': 'Ongeldige geboortedatum',
  Brand: 'Merk',
  'By: {author}': 'Door: {author}',
  Campaign: 'Campagne',
  Cancel: 'Annuleren',
  Canceled: 'Geannuleerd',
  Cart: 'Winkelwagen',
  Categories: 'Categorieën',
  Change: 'Wijzigen',
  'Change password': 'Wijzig wachtwoord',
  'Change password your account':
    'Wanneer je het wachtwoord wil wijzigen voor toegang tot je account, volg de volgende instructies',
  'Change to grid view': 'Wisselen naar rasterweergave',
  'Change to list view': 'Wisselen naar lijstweergave',
  'Choose an option': 'Kies een optie',
  'Choose your language': 'Kies je taal',
  City: 'Stad',
  'Clear all': 'Alles wissen',
  Close: 'Sluiten',
  'Close search': 'Zoeken sluiten',
  closed: 'gesloten',
  'Cocktail & Party': 'Cocktail & Party',
  Color: 'Kleur',
  'Colorful summer dresses are already in store': 'Kleurrijke zomerjurkjes liggen al in de winkel',
  Colors: 'Kleuren',
  'COMING SOON': 'COMING SOON',
  'Coming soon form description': 'Vul je e-mailadres in en ontvang een e-mail op de releasedag!',
  'Coming soon form title': 'Ontvang een e-mail als dit item gereleased is!',
  'Commercial information':
    'en ga ermee akkoord om gepersonaliseerde commerciële informatie van My Jewellery per e-mail te ontvangen',
  'Contact Details': 'Contactgegevens',
  'Contact details updated': 'Houd je adressen en contactgegevens up-to-date.',
  'Contact us': 'Neem contact met ons op',
  'Continue shopping': 'Verder winkelen',
  'Continue to billing': 'Doorgaan naar facturering',
  'Continue to payment': 'Doorgaan naar betaling',
  'Continue to shipping': 'Doorgaan naar verzending',
  'Cookies Link': '/privacy-cookies',
  'Cookies Policy': 'Cookiebeleid',
  Country: 'Land',
  'Create an account': 'Account aanmaken',
  'Current Password': 'Huidig wachtwoord',
  'Customer Reviews': 'Klanten reviews',
  'Customer service': 'Klantenservice',
  Date: 'Datum',
  'Date of birth': 'Geboortedatum',
  Day: 'Dag',
  Default: 'Standaard',
  'Default Billing Address': 'Standaard factuuradres',
  'Default Shipping Address': 'Standaard verzendadres',
  Delete: 'Wissen',
  'Delivery & Payment': 'Betaal- & verzendmethoden',
  'Delivery & Returns': 'Bezorgen & Retourneren',
  Departments: 'Afdelingen',
  Description: 'Omschrijving',
  'Details and status orders':
    'Bekijk de details en status van je bestellingen in de webshop. Je kunt je bestelling ook annuleren of een retourzending aanvragen.',
  Discount: 'Korting',
  'Discover our new items!': 'Ontdek onze nieuwe items!',
  Done: 'Klaar',
  'Done. You’ve subscribed': 'Klaar. Je bent aangemeld',
  Download: 'Download',
  'Download all': 'Alles downloaden',
  'Download our application to your mobile': 'Download onze applicatie naar je mobiel',
  Dresses: 'Jurken',
  'Ear piercing in': 'gaatjes schieten in',
  'Eco Sandals': 'Eco Sandalen',
  Edit: 'Bewerken',
  'Email address': 'E-mailadres',
  'Email you are using to sign in': 'E-mail waarmee je je aanmeldt:',
  'Empty bag': 'Shoppingbag leegmaken',
  'Enjoy your free account': 'Geniet van deze voordelen met je gratis account!',
  'Enter promo code': 'Voer kortingscode in',
  'Enter your email address': 'Vul je e-mailadres in',
  'Existing customers': 'Geregistreerde klanten',
  'Extended overview': 'Uitgebreid overzicht',
  'Fashion to take away': 'Mode om mee te nemen',
  Features: 'Kenmerken',
  Feedback: 'Jouw feedback is belangrijk voor ons. Laat ons weten wat we kunnen verbeteren.',
  'Feel free to edit': 'Wijzig je gegevens hieronder zodat je account altijd up-to-date is',
  Filters: 'Filters',
  'Filters & sorting': 'Filters & sorteren',
  'Find out more': 'Ontdek meer',
  'Find stunning women’s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.':
    'Vind prachtige cocktailjurken en feestjurken voor dames. Val op met kanten en metallic cocktailjurken van al je favoriete merken.',
  'First Name': 'Voornaam',
  Fit: 'Pasvorm',
  'Forgot Password': 'Als je je wachtwoord bent vergeten, kun je het resetten.',
  'Forgot Password Modal Email': 'E-mail waarmee je je aanmeldt:',
  'Forgot Your Password?': 'Wachtwoord vergeten?',
  forgotPasswordConfirmation:
    'Bedankt! Als er een account geregistreerd is met het {0} e-mailadres, vind je een bericht met een link om het wachtwoord te resetten in je inbox.',
  'Forgotten password?': 'Wachtwoord vergeten?',
  'Free shipping': 'Gratis verzending',
  'Get inspired': 'Laat je inspireren',
  'Gift? Add a gift wrap!': 'Cadeautje? Voeg een cadeauverpakking toe!',
  'Go back': 'Ga terug',
  'Go back shopping': 'Ga terug shoppen',
  'Go back to shop': 'Ga terug naar shop',
  'Go to checkout': 'Ga naar kassa',
  'Go To Product': 'Ga naar product',
  'Grand Total': 'Eindtotaal',
  Guarantee: 'Garantie',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  hide: 'Verbergen',
  'Highest price': 'Hoogste prijs',
  Home: 'Home',
  'House/Apartment number': 'Huis/appartementnummer',
  'I agree to': 'Ik ga akkoord met',
  'I confirm subscription': 'Ik bevestig mijn inschrijving',
  'I have read and understand the': 'Ik heb gelezen en begrijp de',
  'I want to create an account': 'Ik wil een account aanmaken',
  'In stock': 'Op voorraad',
  'Info after order':
    'Je kunt inloggen op je account met het eerder gedefinieerde e-mailadres en wachtwoord. Op je account kun je je profielgegevens bewerken, je bestelgeschiedenis bekijken en je abonnement op de nieuwsbrief wijzigen.',
  Instruction1: 'Zorg voor mij',
  Instruction2: 'Alleen hier voor de verzorgingsinstructies?',
  Instruction3: 'Ja, dat dachten we al',
  'Invalid email': 'Ongeldige e-mail',
  'Invalid Password Message':
    'Het wachtwoord moet ten minste 8 tekens lang zijn en moet ten minste bevatten: 1 hoofdletter of kleine letter, 1 cijfer, of een speciaal teken (bijvoorbeeld: , . _ & ? etc.)',
  'It was not possible to request a new password, please check the entered email address.':
    'Het was niet mogelijk om een nieuw wachtwoord aan te vragen. Controleer het ingevoerde e-mailadres.',
  Item: 'Item',
  Items: 'Items',
  'Keep me updated': 'Houd me op de hoogte!',
  Kidswear: 'Kinderkleding',
  'Last Name': 'Achternaam',
  'Learn more': 'Meer informatie',
  'Let’s create an account. It has many advantages, such as order tracking, fast checkout and registration of multiple addresses.':
    'Let’s create an account! Want dat heeft veel voordelen, zoals je bestelling volgen, je bestelling sneller afhandelen en meer dan één adres registreren.',
  'Let’s start now – we’ll help you': 'Let’s start now – wij helpen je.',
  'Linen Dresses': 'Linnen Jurken',
  'Load more reviews': 'Laad meer reviews',
  'Log in to save your favourites!': 'Log in om je favourites op te slaan!',
  'Log into your account': 'Inloggen op je account',
  'Log out': 'Uitloggen',
  Login: 'inloggen',
  'login in to your account': 'Inloggen op je account',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Het lijkt erop dat je nog niets aan je shoppingbag hebt toegevoegd. Begin met shoppen om te vullen.',
  'Lowest price': 'Laagste prijs',
  'Make an order': 'Plaats een bestelling',
  'Manage Addresses': 'Adressen beheren',
  'Manage addresses':
    'Beheer alle adressen die je wilt (werkadres, thuisadres...) Zo hoef je het adres niet bij elke bestelling handmatig in te voeren.',
  'Manage billing addresses':
    'Beheer alle factuuradressen die je wilt (werkadres, thuisadres...) Zo hoef je het factuuradres niet bij elke bestelling handmatig in te voeren.',
  'Manage shipping addresses':
    'Beheer alle verzendadressen die je wilt (werkadres, thuisadres...) Zo hoef je niet bij elke bestelling handmatig het verzendadres in te voeren.',
  'Match it with': 'Style dit met',
  Material: 'Materiaal',
  'Men fashion': 'Mannen mode',
  Menu: 'Menu',
  Month: 'Maand',
  'Month flower': 'Maand bloem',
  more: 'meer',
  'My Account': 'Mijn Account',
  'My billing and shipping address are the same': 'Mijn factuur- en verzendadres zijn hetzelfde',
  'My Cart': 'Mijn shoppingbag',
  'My newsletter': 'Mijn nieuwsbrieven',
  'My orders': 'Mijn bestellingen',
  'My profile': 'Mijn profiel',
  'My reviews': 'Mijn reviews',
  'My wishlist': 'Mijn wishlist',
  Name: 'Naam',
  'Name A-Z': 'Naam A-Z',
  'New customer': 'Nieuwe klant',
  'New Password': 'Nieuw wachtwoord',
  'New Products': 'Nieuwe producten',
  Newest: 'Nieuwste',
  'No account': 'Heb je nog geen account?',
  'No products for query "{keyword}"': 'Geen producten voor zoekopdracht "{keyword}"',
  'No products were found for the search query':
    'We zijn aan het zoeken naar "<strong>{query}</strong>" voor je. Druk op enter of klik op het vergrootglas om alle zoekresultaten te bekijken.',
  'No results for “{keyword}”': 'Geen resultaten voor “{keyword}”',
  'Notify me on release': 'Breng me op de hoogte bij release',
  'Open search': 'Zoeken openen',
  'Opening hours': 'Openingstijden',
  option: 'optie | opties',
  or: 'of',
  'or fill the details below': 'of vul de onderstaande gegevens in',
  'Order {price} more for free shipping': 'Bestel nog {price} meer voor gratis verzending',
  'Order details': 'Bestelgegevens',
  'Order history': 'Bestel geschiedenis',
  'Order information': 'Bestelgegevens',
  'Order No.': 'Ordernummer',
  'Order summary': 'Overzicht bestelling',
  'Original price': 'Prijs',
  'Other products you might like': 'Andere producten die je misschien leuk vindt',
  'Out of stock': 'Uitverkocht',
  Overview: 'Overzicht',
  Password: 'Wachtwoord',
  'Password change': 'Wachtwoordwijziging',
  'Password Changed': 'Wachtwoord succesvol gewijzigd. Je kunt nu teruggaan naar de startpagina en jezelf aanmelden.',
  'Passwords don’t match': 'Wachtwoorden komen niet overeen',
  'Pay for order': 'Bestelling betalen',
  Payment: 'Betaling',
  'Payment & Delivery': 'Betaling & levering',
  'Payment & shipping methods': 'Betaling & verzendmethodes',
  'Payment method': 'Betaalmethode',
  'Payment methods': 'Betaalmethodes',
  'Personal data': 'Persoonlijke gegevens',
  'Personal details': 'Persoonlijke Gegevens',
  'Personal Details': 'Persoonlijke Gegevens',
  'Personal information': 'Inloggegevens',
  'Phone number': 'Telefoonnummer',
  'Phone model': 'Smartphone model',
  'Please enter your email address below to receive a password reset link.':
    'Voer hieronder je e-mailadres in om een link te ontvangen waarmee je je wachtwoord kunt resetten.',
  'Please select a country first': 'Selecteer eerst een land',
  'Please select the {names} below': 'Selecteer hieronder {names}',
  'Please select a color first': 'Selecteer eerst een kleur',
  'Please select the option above first': 'Selecteer eerst bovenstaande optie',
  'Please type your current password to change your email address.':
    'Typ je huidige wachtwoord om je e-mailadres te wijzigen.',
  Price: 'Prijs',
  Privacy: 'Privacy',
  'Privacy Link': '/nl-nl/privacy-cookies',
  'Privacy Policy': 'Privacy beleid',
  Product: 'Product',
  product: 'product | producten',
  'Product recommendations unknown user': 'Ontdek onze bestsellers',
  'Product recommendations with name': 'Deze items zijn ook echt iets voor jou, {firstName}',
  'Product recommendations with name - male': 'Ontdek meer cadeau inspiratie, {firstName}',
  'Product recommendations without name': 'Deze items zijn ook echt iets voor jou',
  'Product recommendations without name - male': 'Ontdek meer cadeau inspiratie',
  'Product suggestions': 'Productsuggesties',
  Products: 'Producten',
  'Products found': 'Gevonden producten',
  'Entre ton code de réduction': 'Kortingscode',
  'Purchase terms': 'Aankoopvoorwaarden',
  'Quality in the details': 'Kwaliteit in de details',
  Quantity: 'Aantal',
  Rating: 'Beoordelingen',
  'Read all reviews': 'Lees alle reviews',
  'Read and understand': 'Ik heb gelezen en begrijp de',
  'Read less': 'Lees minder',
  'Read more': 'Lees meer',
  'Read reviews': 'Lees reviews',
  'Reading time: {readingTime} minutes': 'Leestijd: {readingTime} minuten',
  Recommended: 'Aanbevolen',
  Register: 'Registreer',
  'Register today': 'Registreer vandaag',
  'Remove Address': 'Adres verwijderen',
  'Remove from Wishlist': 'Verwijderen uit wishlist',
  'Repeat Password': 'Herhaal Wachtwoord',
  'Reset Password': 'Reset Wachtwoord',
  'Return home': 'Terug naar home',
  Returns: 'Retouren',
  Review: 'Review',
  'Review my order': 'Review mijn bestelling',
  Reviews: 'Reviews',
  SALE: 'SALE',
  'Same as shipping address': 'Hetzelfde als verzendadres',
  'Save changes': 'Wijzigingen opslaan',
  'Save for later': 'Bewaar voor later',
  'Save Password': 'Wachtwoord opslaan',
  Search: 'Zoek',
  'Search for items': 'Zoek voor items',
  'Search for products, ...': 'Producten zoeken, ...',
  'Search for products, categories, ...': 'Zoek producten, categorieën, ...',
  'Search results': 'Zoekresultaten',
  'Search results for: “{keyword}”': 'Zoekresultaten voor: “{keyword}”',
  Season: 'Seizoen',
  'Sections that interest you': 'Rubrieken die je interesseren',
  'See all': 'Bekijk alles',
  'See all products': 'Bekijk alle producten',
  'See all results': 'Bekijk alle resultaten',
  'See our new items link': '/nl-nl/nieuw.html',
  'See our new items!': 'Ga naar nieuwe items',
  'Select payment method': 'Selecteer betaalmethode',
  'Select shipping method': 'Selecteer verzendmethode',
  'Send my feedback': 'Verstuur mijn feedback',
  'Set as default billing': 'Instellen als standaardfacturering',
  'Set as default shipping': 'Instellen als standaardverzending',
  'Set up newsletter':
    'Stel je nieuwsbrief in en we sturen je elke week informatie over nieuwe producten en trends uit de rubrieken die je hebt geselecteerd.',
  'Share your experience and help others make the right decision':
    'Deel jouw ervaringen en help anderen een goede keuze te maken',
  'Share your look': 'Deel je look',
  'Share Your Look': 'Deel je look',
  Shipping: 'Bezorgen',
  'Shipping address': 'Verzendadres',
  'Shipping details': 'Verzendgegevens',
  'Shipping method': 'Verzendmethode',
  'Shop now': 'Shop nu',
  'Show all': 'Bekijk alles',
  'Show all results': 'Bekijk alle resultaten',
  'Show less shop-in-shops': 'Bekijk minder shop-in-shops',
  'show more': 'Bekijk meer',
  'Show more products': 'Bekijk meer producten',
  'Show more shop-in-shops': 'Bekijk meer shop-in-shops',
  'Show on page': 'Weergeven op pagina',
  'Show previous products': 'Bekijk eerdere producten',
  'Sign in': 'Aanmelden',
  'sign me up': 'schrijf me in',
  'Sign Up for Newsletter':
    'Ja, ik wil me aanmelden om 10% korting, updates over nieuwe producten en de laatste aanbiedingen te ontvangen.',
  'Sign-in information': 'Inloggevens',
  Size: 'Maat',
  'Size chart': 'Maattabel',
  'Size guide': 'Maattabel',
  'Sku color': 'Kleur',
  'Sold out form description':
    'Helaas is dit item nu niet meer op voorraad, maar it’s coming back! Schrijf je in en ontvang een e-mail wanneer je favoriete kleur of maat back in stock is.',
  'Sold out form title': 'Ontvang een e-mail als dit item op voorraad is!',
  'Sorry, the page you are looking for does not seem to exist anymore.':
    'Sorry, de pagina die je zoekt lijkt niet meer te bestaan.',
  'Sort by': 'Sorteren op',
  'Sort: Default': 'Standaard',
  'Sort: Name A-Z': 'Naam A-Z',
  'Sort: Name Z-A': 'Naam Z-A',
  'Sort: Price from high to low': 'Prijs van hoog naar laag',
  'Sort: Price from low to high': 'Prijs van laag naar hoog',
  'Start shopping': 'Start shopping',
  'State/Province': 'Staat/Provincie',
  'Street Name': 'Straatnaam',
  'Style profile': 'Stijl',
  Subscribe: 'Inschrijven',
  'Subscribe to newsletter': 'Inschrijven op de nieuwbsrief',
  'Subscribe to Newsletters': 'Inschrijven op nieuwsbrieven',
  'Subscribe your friend!': 'Meld je vriend(in) nu aan!',
  subscribeToNewsletterModalContent:
    'Als u zich aanmeldt voor de nieuwsbrief, ontvangt u speciale aanbiedingen en berichten van My Jewellery via e-mail. Wij zullen je e-mail op geen enkel moment verkopen of verspreiden aan derden. Zie onze {0}.',
  Subtotal: 'Subtotaal',
  'Subtotal price': 'Subtotaal prijs',
  'Successful placed order':
    'Je hebt succesvol de bestelling geplaatst. Je kunt de status van je bestelling controleren met behulp van onze leveringsstatusfunctie. Je ontvangt een e-mail ter bevestiging van je bestelling met de details van je bestelling en een link om de voortgang te volgen.',
  Summary: 'Samenvatting',
  'SUMMER COLLECTION {year}': 'ZOMER COLLECTIE {year}',
  'Summer Sandals': 'Zomer Sandalen',
  'T-Shirts': 'T-Shirts',
  Tax: 'Btw',
  'Terms and conditions': 'Algemene voorwaarden',
  'Thank You Inbox':
    'Als het bericht niet aankomt in je inbox, probeer dan een ander e-mailadres dat je mogelijk hebt gebruikt om je te registreren.',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'De aanmelding voor het account was onjuist of het account is tijdelijk uitgeschakeld. Probeer het later opnieuw.',
  'The email field must be a valid email': 'Het e-mailadres moet geldig zijn',
  'The field should have at least 2 characters': 'Het veld moet minstens 2 tekens bevatten',
  'The field should have at least 4 characters': 'Het veld moet minstens 4 tekens bevatten',
  'The field should have at least 8 characters': 'Het veld moet minstens 8 tekens bevatten',
  'The office life': 'The office life',
  'The Office Life': 'The Office Life',
  'The user account data was successfully updated!': 'De gebruikersgegevens zijn succesvol bijgewerkt!',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'Er zijn geen verzendmethoden beschikbaar voor dit land. Sorry, probeer het met een ander land.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'Er is een fout opgetreden bij het ophalen van de verzendmethoden. Sorry, probeer het met andere verzendgegevens.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'Er is een fout opgetreden bij het selecteren van deze verzendmethode. Sorry, probeer een andere verzendmethode.',
  'These are our bestsellers': 'Dit zijn onze bestsellers',
  'These are your last viewed items': 'Dit zijn je laatst bekeken items',
  'This field is required': 'Dit veld is verplicht',
  'This is a required field.': 'Dit is een verplicht veld.',
  Total: 'Totaal',
  'Total items': 'Aantal items',
  'Total price': 'Totaalbedrag',
  'Update password': 'Update wachtwoord',
  'Update personal data': 'Update persoonlijke gegevens',
  'Update the address': 'Update adres',
  'Use your personal data':
    'Bij My Jewellery hechten we veel belang aan privacykwesties en zetten we ons in om de persoonlijke gegevens van onze gebruikers te beschermen. Kom meer te weten over hoe wij uw persoonlijke gegevens verzorgen en gebruiken in de',
  'User Account': 'Gebruikersaccount',
  View: 'Bekijk',
  'View all items on your wishlist': 'Bekijk alle items op jouw wishlist',
  'View details': 'Details bekijken',
  'View our newest items & items that have been saved especially for you below! Or shop from one of our categories.':
    'Bekijk hieronder onze nieuwste items & items die speciaal voor jou zijn bewaard! Of shop uit een van onze categorieën.',
  'Vue Storefront Next': 'Vue Storefront Next',
  'We believe you might like these items': 'Wij denken dat je deze items leuk vindt',
  'We haven’t found any results for given phrase': 'We hebben geen resultaten gevonden voor de opgegeven zin',
  'We think you’ll like these items': 'Deze items zal je ook leuk vinden',
  'Who we are': 'Wie wij zijn',
  Wishlist: 'Wishlist',
  'Women fashion': 'Vrouwen mode',
  'Write a review': 'Schrijf een review',
  Year: 'Jaar',
  'You added {product} to your shopping cart.': 'Je hebt {product} aan de winkelwagen toegevoegd.',
  'You already have an account. We like it! Use your email address to log in.':
    'Je hebt al een account. We like it! Gebruik je e-mailadres om in te loggen.',
  'You are not authorized, please log in': 'Je bent niet gemachtigd, log in.',
  'You can try one of the popular search queries': 'Je kan deze populaire zoektermen proberen',
  'You can unsubscribe at any time': 'Je kunt je op elk moment uitschrijven',
  'You currently have no orders': 'Je hebt momenteel geen bestellingen',
  'You have no items in your shopping cart.': 'Je hebt geen product(en) in jouw winkelwagen.',
  'You have no items in your wishlist.': 'Je hebt geen artikelen in jouw wishlist.',
  'You have submitted no reviews': 'Je hebt nog geen reviews ingediend',
  'You haven’t searched for items yet': 'Je hebt nog niet gezocht naar items.',
  'You must login or register to add items to your wishlist.':
    'Je moet inloggen of registreren om items aan jouw wishlist toe te voegen.',
  'Your bag is empty': 'Je winkelmand is leeg',
  'Your cart is empty': 'Je winkelmand is leeg',
  'Your current email address is': 'Je huidige e-mailadres is',
  'Your e-mail': 'Je e-mail',
  'Your rating': 'Jouw waardering',
  'Your review was submitted!': 'Je review is ingediend!',
  'Zip-code': 'Postcode',
  'Zodiac sign': 'Sterrenbeeld',
  // Translated after MYJE-2429
  Title: 'Titel',
  'Launch:': 'Lancering: {dateOnline}',
  'Nights sleep': 'Nog {nights} nachtjes slapen',
  'Adding product to wishlist...': 'Product wordt toegevoegd aan wishlist...',
  'Adding product to cart...': 'Aan winkelmand toevoegen...',
  'Login to add to wishlist': 'Login om aan wishlist toe te voegen',
  'You have to be logged in to add items to your wishlist.':
    'Je moet ingelogd zijn om items aan je wishlist toe te voegen.',
  'Added to your cart!': 'Toegevoegd aan je winkelwagen!',
  'Maximum characters': 'Maximaal {amount} tekens',
  'See images for the order of engraving': 'Zie foto’s voor graveervolgorde',
  'Personalise your jewellery (capital letters only)': 'Personaliseer je sieraad (alleen hoofdletters)',
  'Engraving:': 'Gravure:',
  'No results for selected filters': 'Geen resultaten voor geselecteerde filters',
  'No results for this category': 'Geen resultaten voor deze categorie',
  'Please select an available {names}': 'Selecteer een beschikbare {names}',
  'Choose an option notification': 'Kies uit de beschikbare opties',
  customisation: 'personalisatie',
  'Giftcard price': 'Giftcard prijs',
  'Birth year': 'Geboortejaar',
  'Birth month': 'Geboortemaand',
  Initial: 'Initiaal',
  'Please only enter letters': 'Voer alleen letters in',
  'You can enter gift card codes when you proceed to checkout':
    'Giftcard codes kun je invullen als je doorgaat met afrekenen',
  'Promo code': 'Kortingscode',
  "Unfortunately, this item is sold out and can't be added to your shopping cart.":
    'Dit product is helaas uitverkocht en kan daarom niet toegevoegd worden aan het winkelmandje.',
  variantMatrixSize: 'je maat',
  variantMatrixColor: 'een kleur',
  variantMatrixBirthYear: 'een geboortejaar',
  variantMatrixBirthMonth: 'een geboortemaand',
  variantMatrixZodiacSign: 'een sterrenbeeld',
  variantMatrixInitial: 'een initiaal',
  variantMatrixGiftcardPrice: 'een giftcard waarde',
  variantMatrixEngraving: 'jouw persoonlijke graveertekst',
  variantMatrixPhoneModel: 'een smartphone model',
  "That's one shiny combination!": "That's one shiny combination!",
  'No size chart available': 'Geen maattabel beschikbaar',
  'Please select {names}': 'Selecteer {names}',
  loginToAddToWishlist: 'Je moet ingelogd zijn om items aan je wishlist toe te voegen, ga naar {0}.',
  'login page': 'login pagina',
  Cards: 'Kaarten',
  'Gift bags': 'Gift bags',
  'Gift boxes': 'Gift boxes',
  'You added {product} to your wishlist.': 'Je hebt {product} aan je wishlist toegevoegd.',
  NOT_SALABLE: 'Helaas is dit item niet meer op voorraad.',
  'Your opinion counts': 'Reviews',
  Trends: 'Trends',
  Engravable: 'Graveerbaar',
  'Color groups': 'Kleurgroepen',
  'Ends in': 'Eindigt in',
  'Invalid phone number': 'Ongeldig telefoonnummer',
  'Invalid prefix phone number': 'Begin je telefoonnummer met +31 of +32',
  'The field should have at least {amount} characters': 'Het veld moet minstens {amount} tekens bevatten',
  'Something went wrong': 'Er is iets misgegaan',
  PRODUCT_NOT_FOUND:
    'We kunnen het product dat je zoekt niet vinden. Het is mogelijk niet beschikbaar of bestaat niet.',
  NOT_SALABLE: 'Sorry, dit product is momenteel niet op voorraad en kan niet worden gekocht.',
  CART_NOT_FOUND:
    'Oeps! We kunnen je winkelwagen niet vinden. Het is mogelijk al omgezet in een bestelling of bestaat niet meer. Probeer het opnieuw.',
  CART_NOT_ACTIVE: 'Je winkelwagen is inactief. Probeer het opnieuw.',
  CART_AUTHORIZATION_FAILED: 'We konden je winkelwagen niet autoriseren. Probeer het opnieuw.',
  'In cart': 'In winkelwagen',
  'Productgroup old': 'Productgroep oud',
  'Necklace length': 'Kettinglengte',
}
