// https://staging-core.dxpapi.com/api/v1/core/?
// account_id=6695
// &auth_key=8clkfyt5d9x79qj4
// &domain_key=myjewellery_de
// &request_id=5757996310600
// &ref_url=http://localhost:3000
// &url=http://localhost:3000/search?q=blkca
// &request_type=search
// &rows=20
// &start=0
// &fl=pid,title,brand,price,sale_price,colors,sizes,thumb_image,price_range,sale_price_range
// &search_type=keyword
// &q=blkca

// add sku_ in front of reserved attributes if you want to add it to variant. So sku_price, sku_size, sku_color, etc.

import type {
  ParsedSearchResponse,
  SearchConfig,
  SearchResponse,
  OverridesConfig,
  Query,
} from './bloomreachDiscoveryApi'

const queryFields = [
  'pid',
  'sku',
  'title',
  'url',
  'brand',
  'original_price',
  'price',
  'sale_price',
  'colors',
  'style_swatches_hex',
  'active_colors_hex',
  'sizes',
  'product_label',
  'thumb_image',
  'thumbnail',
  'hover_image_url',
  'out_of_stock',
  'date_online',
  'reviews_rating_summary',
  'review_count',
  'color',
  'filter_image',
  'sku_color',
  'sku_size',
]

export const useBloomreachDiscoveryApi = () => {
  const logger = useAppLogger('useBloomreachDiscoveryApi')
  const config = useRuntimeConfig()
  const {
    $i18n: { locale },
  } = useNuxtApp()
  const { transformImageUrlToSize, ImageSize } = useMagentoImage()
  const requestId = 'id-' + Math.random().toString(16).slice(2)
  const { stagingEnabled, accountId, authKey, domainKey } = config.public.bloomreachDiscovery

  const domainKeys = Object.fromEntries(domainKey.split(',').map((k) => k.split('=')))
  const currentDomainKey = domainKeys[locale.value?.substring(0, 2)] || Object.values(domainKeys)[0]

  const sortMap = {
    newest: 'pid+desc',
    price: 'price+asc',
    price_high: 'price+desc',
    name: 'title+asc',
  }

  /**
   * Adds images to the object.
   *
   * @param {SearchResponse} data - Endpoint data.
   * @returns {SearchResponse}
   */
  const parseResponseData = (data: SearchResponse): ParsedSearchResponse => {
    data.response.docs = data.response.docs.map((doc) => ({
      ...doc,
      hover_image_url: transformImageUrlToSize(doc.hover_image_url, ImageSize.Default, 'bloomreach'),
      thumb_image: transformImageUrlToSize(doc.thumb_image, ImageSize.Default, 'bloomreach'),
      thumbnail: transformImageUrlToSize(doc.thumbnail, ImageSize.Default, 'bloomreach'),
    }))

    return data as ParsedSearchResponse
  }

  /**
   * Searches for products based on the provided configuration.
   *
   * @param {SearchConfig} config - Configuration options for the search.
   * @param {OverridesConfig} overrides - Optional overrides for the request parameters.
   * @returns {Promise<ParsedSearchResponse>}
   */
  const searchProducts = async (
    config: SearchConfig,
    overrides: OverridesConfig = {},
  ): Promise<ParsedSearchResponse> => {
    const {
      q = '',
      fq = [],
      start = 0,
      rows = 20,
      fl = queryFields.join(','),
      search_type = 'keyword',
      sort = '',
      url = '',
    } = config

    const query: Query = {
      account_id: accountId,
      auth_key: authKey,
      domain_key: currentDomainKey,
      request_id: requestId,
      ref_url: url,
      url,
      request_type: 'search',
      search_type,
      fl,
      rows,
      sort: sortMap[sort] || '',
      start,
      q,
      fq: formatFacetsQuery(fq),
      'facet.version': '3.0',
      'facet.range': 'price',
      ...overrides,
    }

    if (import.meta.client) {
      const brID = useCookie('_br_uid_2')
      if (brID.value) query._br_uid_2 = encodeURIComponent(brID.value)
    }

    try {
      const cacheKey = query.url + query.q + query.start + query.rows + query.fq + query.sort + query.search_type
      const { data } = useNuxtData(cacheKey)
      if (!data.value) {
        logger.info('fetching data', cacheKey)

        const queryString = Object.keys(query)
          .map((key) => {
            return `${key}=${query[key]}`
          })
          .join('&')
          .replaceAll('%2B', '+')

        data.value = await $fetch(
          `https://${stagingEnabled ? 'staging-' : ''}core.dxpapi.com/api/v1/core/?${queryString}`,
        )
      } else {
        logger.info('using cached data', cacheKey)
      }

      return parseResponseData(data.value)
    } catch (error) {
      logger.error(error)
      return {
        facet_counts: {
          facet_fields: {},
        },
        success: false,
        response: {
          docs: [],
          numFound: 0,
          start: 0,
        },
      } as unknown as ParsedSearchResponse
    }
  }

  /**
   *
   * @param facets Object with facet key value pairs
   * @returns Formatted facet query string
   *
   * Formats field-query like so: &fq=color: "red" OR "purple"
   * Formats price like: &fq=price:[15 TO 20]
   */
  const formatFacetsQuery = (facets: any) =>
    Object.entries(facets)
      .filter(([k, v]) => v !== null)
      .map(([k, v]: [k: string, v: string]) => {
        if (k === 'price') {
          const p = Array.isArray(v) ? v : v.split(',')
          return `${k}:[${p[0]} TO ${p[1]}]`
        }
        return `${k}:%22${encodeURIComponent(Array.isArray(v) ? v.join('" OR "') : v)}%22`
      })

  return {
    searchProducts,
  }
}

export default useBloomreachDiscoveryApi
