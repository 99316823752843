import { defineStore } from 'pinia'
import { useExponeaApi } from '~/composables/useExponeaApi'
import type { ExponeaProduct } from '~/types/exponea'

export class ExponeaUser {
  firstname: string
  lastname: string
  email: string
  phone: string
  city: string
  postcode: string
  address: string
  style: string
  gender: string
  jewellery_preference: string
}

export interface ExponeaRecommendation {
  id: string
  products: ExponeaProduct[]
  loading: boolean
}

export interface ExponeaSegmentation {
  id: string
  value: string
}

export interface ExponeaItemset {
  sku: string
  products: ExponeaProduct[]
}

export interface ExponeaState {
  user: ExponeaUser
  userLoading: boolean
  recommendations: ExponeaRecommendation[]
  segmentations: ExponeaSegmentation[]
  products: ExponeaProduct[]
  itemsets: ExponeaItemset[]
}

export const useExponeaStore = defineStore('exponea', () => {
  const logger = useAppLogger('useExponeaStore')
  const state: ExponeaState = reactive({
    user: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      city: '',
      postcode: '',
      address: '',
      style: '',
      gender: '',
      jewellery_preference: '',
    },
    userLoading: true,
    recommendations: [],
    segmentations: [],
    products: [],
    itemsets: [],
  })

  const getRecommendationById = (id: string) => {
    const recommendations = state.recommendations.find((recommendation) => id === recommendation.id)
    if (!recommendations) return []
    if (recommendations.products.length > 0) return recommendations.products
    return []
  }
  const getRecommendationLoadingStateById = (id: string) => {
    const recommendations = state.recommendations.find((recommendation) => id === recommendation.id)
    return recommendations?.loading === true
  }
  const getExponeaRecommendationById = (id: string) =>
    state.recommendations.find((recommendation) => id === recommendation.id)?.products
  const getSegmentationById = (id: string) => state.segmentations.find((segmentation) => id === segmentation.id)?.value
  const getProductBySku = (sku: string) => state.products.find((product) => sku === product.sku)

  const getUser = async () => {
    state.userLoading = true

    // https://documentation.bloomreach.com/engagement/reference/customer-properties-test
    await waitUntilExponeaCookieIsSet()
    const userId = useCookie('__exponea_etc__').value
    const customerStore = useCustomerStore()
    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'property',
          property: 'firstname',
        },
        {
          type: 'property',
          property: 'lastname',
        },
        {
          type: 'property',
          property: 'email',
        },
        {
          type: 'property',
          property: 'gender',
        },
        {
          type: 'property',
          property: 'days_till_birthday',
        },
        {
          type: 'property',
          property: 'perfume_favo_drink',
        },
        {
          type: 'property',
          property: 'postcode',
        },
        {
          type: 'property',
          property: 'address',
        },
        {
          type: 'property',
          property: 'style',
        },
        {
          type: 'property',
          property: 'jewellery_preference',
        },
      ],
    }

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email
    } else {
      body.customer_ids['cookie'] = userId
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn('[Exponea] No user id found - getUser')
    }

    const { post } = useExponeaApi()

    try {
      const results = await post('/customers/attributes', body)
      if (!results) return
      results.forEach((result, index: number) => {
        const property = body.attributes[index].property
        state.user[property] = result.value
      })
    } catch (error) {
      logger.error(error)
    } finally {
      state.userLoading = false
    }
  }

  const getRecommendation = async ({ id, productId = null, fillWithRandom, size, force = false }) => {
    // https://documentation.bloomreach.com/engagement/reference/customer-recommendations
    // Use force if you need to do a new call, otherwise it'll use the existing one
    if (!id) return logger.warn('[Exponea] No recommendation ID provided')
    let recommendationFromState: ExponeaRecommendation = state.recommendations.find(
      (recommendation) => recommendation.id === id,
    )
    if (recommendationFromState && !force) return
    if (!recommendationFromState) {
      state.recommendations.push({
        id,
        products: [],
        loading: true,
      })
      recommendationFromState = state.recommendations.find((recommendation) => recommendation.id === id)
    }

    await waitUntilExponeaCookieIsSet()
    const { post } = useExponeaApi()
    const customerStore = useCustomerStore()
    const userId = useCookie('__exponea_etc__').value

    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'recommendation',
          id,
          fillWithRandom,
          size,
          catalogAttributesWhitelist: [
            'title',
            'image',
            'hover_image_url',
            'price',
            'url',
            'reviews_rating_summary',
            'review_count',
            'sku',
            'date_online',
          ],
          items: {},
        },
      ],
    }

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email
    } else {
      body.customer_ids['cookie'] = userId
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn(`[Exponea] No user id found - getSegmentation ${id}`)
    }

    if (productId) {
      body.attributes[0].items = { [productId]: 1 }
    }

    try {
      const products = await post('/customers/attributes', body)
      if (!products || !products?.[0]?.value?.length) return
      const productsWithTypeName = products[0].value.map((product) => {
        return { ...product, __typename: 'ExponeaProduct' }
      })
      recommendationFromState.products = productsWithTypeName
    } catch (error) {
      logger.error(error)
    } finally {
      recommendationFromState.loading = false
    }
  }
  const getSegmentation = async ({ id }) => {
    // https://documentation.bloomreach.com/engagement/reference/customer-segmentations-test
    if (getSegmentationById(id)) return
    const { post } = useExponeaApi()
    const customerStore = useCustomerStore()
    await waitUntilExponeaCookieIsSet()
    const userId = useCookie('__exponea_etc__').value
    const body = {
      customer_ids: {},
      attributes: [
        {
          type: 'segmentation',
          id,
        },
      ],
    }

    if (customerStore.user?.email) {
      body.customer_ids['registered'] = customerStore.user?.email
    } else {
      body.customer_ids['cookie'] = userId
    }

    if (body.customer_ids['cookie'] === undefined && body.customer_ids['registered'] === undefined) {
      return logger.warn(`[Exponea] No user id found - getSegmentation ${id}`)
    }

    try {
      const results = await post('/customers/attributes', body)
      if (!results) return
      state.segmentations.push({
        id,
        value: results[0]?.value,
      })
    } catch (error) {
      logger.error(error)
    }
  }

  const getProduct = async ({ catalogId, sku = null, exponeaId = null }) => {
    // https://documentation.bloomreach.com/engagement/reference/get-catalog-items-2
    // You can use either the SKU or exponeaId to get the product
    const product = getProductBySku(sku)
    if (product) return product

    const { get } = useExponeaApi()
    let url = `/catalogs/${catalogId}/items?limit=1`
    if (sku) url += `&query=${sku}&field=sku`
    if (exponeaId) url += `&query=${exponeaId}&field=item_id`

    try {
      const results = await get(url)
      if (results?.data[0]) {
        const result = { ...results.data[0].properties, id: results.data[0].item_id }
        state.products.push(result) // Add to state
        return result // Return it as well
      }
    } catch (error) {
      logger.error(error)
    }
  }

  const waitUntilExponeaCookieIsSet = async (): Promise<void> => {
    return new Promise(async (resolve) => {
      while (!useCookie('__exponea_etc__').value) {
        await new Promise((resolve) => requestAnimationFrame(resolve))
      }
      resolve()
    })
  }

  return {
    ...toRefs(state),
    waitUntilExponeaCookieIsSet,
    getRecommendationById,
    getRecommendationLoadingStateById,
    getExponeaRecommendationById,
    getSegmentationById,
    getUser,
    getRecommendation,
    getSegmentation,
    getProduct,
  }
})

export default useExponeaStore
