import useExponeaApi from '~/composables/useExponeaApi'

export const useExponea = () => {
  const { post } = useExponeaApi()

  const trackPageVisit = () => {
    if (import.meta.server) return
    const route = useRoute()

    window.exponea.track('page_visit', {
      path: route.fullPath,
      referrer: '',
    })
  }

  return {
    trackPageVisit,
  }
}

export default useExponea
