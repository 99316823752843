import gql from 'graphql-tag'

export default gql`
  fragment wishlistFields on WishlistItems {
    items {
      id
      quantity
      description
      added_at
      ... on ConfigurableWishlistItem {
        configurable_options {
          id
          option_label
          value_label
        }
        configured_variant {
          sku
          thumbnail {
            url
          }
        }
      }
      product {
        ... on BundleProduct {
          items {
            sku
            title
            options {
              uid
              quantity
              product {
                uid
                sku
                name
                price_range {
                  maximum_price {
                    final_price {
                      currency
                      value
                    }
                    regular_price {
                      currency
                      value
                    }
                  }
                  minimum_price {
                    final_price {
                      currency
                      value
                    }
                    regular_price {
                      currency
                      value
                    }
                  }
                }
              }
            }
          }
        }
        uid
        __typename
        sku
        name
        stock_status
        only_x_left_in_stock
        rating_summary
        url_key
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        thumbnail {
          url
          position
          disabled
          label
        }
      }
    }
  }
`
