import type { Cart, ProductInterface, RemoveItemFromCartInput } from '@vue-storefront/magento-types'
import { updateItemQtyCommand } from './updateItemQtyCommand'
import { getGiftcardHolders } from '~/composables/useCart/utils/giftcardHoldersUtils'

export const removeItemCommand = {
  removeGiftCardHolders: () => {
    const cartStore = useCartStore()

    const giftCardHolders = getGiftcardHolders(cartStore.cart.items)

    if (giftCardHolders?.length === 1) {
      if (giftCardHolders[0]?.quantity === 1) {
        removeItemCommand.execute({ product: giftCardHolders[0] })
      } else {
        updateItemQtyCommand.execute({ product: giftCardHolders[0], quantity: giftCardHolders[0]?.quantity! - 1 })
      }
    }
  },
  execute: async ({ product }: { product: ProductInterface }) => {
    const { $sdk } = useNuxtApp()
    const cartStore = useCartStore()

    console.debug('[Magento]: Remove item from cart', {
      product,
      currentCart: cartStore.cart,
    })

    const item = cartStore.cart?.items?.find((cartItem) => cartItem.uid === product.uid)

    if (!item) return

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: cartStore.cart.id,
      cart_item_uid: item.uid,
    }

    const { data, errors } = await $sdk.magento.removeItemFromCart(removeItemParams, {
      removeItemFromCart: 'remove-item-from-cart',
    })

    if (item.product.sku === 'mjgiftcard' && !errors?.length) removeItemCommand.removeGiftCardHolders()

    cartStore.setCart(data?.removeItemFromCart?.cart)

    console.debug('[Result]:', { data, errors })

    if (!data?.removeItemFromCart && errors?.length) {
      throw errors[0]
    }

    // eslint-disable-next-line consistent-return
    return data.removeItemFromCart.cart as unknown as Cart
  },
}
