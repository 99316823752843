import { cartCookieName, customerCookieName, loginRedirectCookieName } from '~/enums/cookieNameEnum'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    public: { storeUrl },
  } = useRuntimeConfig()
  const localePath = useLocalePath()
  const logger = useAppLogger('generalMiddleware.global.ts')

  const customerToken = useCookie(customerCookieName)
  const cartCookie = useCookie(cartCookieName)
  const loginRedirect = useCookie(loginRedirectCookieName)

  const syncVueWithMagento = async (): Promise<void> => {
    const cartId = cartCookie.value?.indexOf('|') ? cartCookie.value?.split('|').pop() : cartCookie.value
    if (!cartId || customerToken.value) return logger.info('[Magento Sync] Skipped')

    const url = `${storeUrl}${localePath('/')}/vue/cart/sync/token/${
      customerToken.value !== undefined ? customerToken.value : ''
    }/cart/${cartId}`

    logger.debug('[Magento Sync] URL: ', url)

    try {
      const response = await $fetch(url)
      if (!response.ok) {
        throw new Error(`status: ${response.status}`)
      }

      logger.debug('[Magento Sync] Done')
    } catch (error) {
      logger.error('[Magento Sync] Error:', error)
    }
  }
  // Add login redirect when going to the login url
  loginRedirect.value = to.path.includes('/customer/account/login') ? window.location.href : undefined
  // Temporary workaround for Magento pages (when not dev), to make sure we leave the Nuxt app when navigating to these pages
  const magentoPages = ['/customer/account', '/wishlist', '/checkout']
  if (magentoPages.some((el) => to.path.includes(el)) && !to.query.token) {
    const config = useRuntimeConfig()
    logger.info(`Redirecting to non-Nuxt page: ${config.public.storeUrl + to.path}`)
    if (!import.meta.dev) {
      await syncVueWithMagento()
      return navigateTo(config.public.storeUrl + to.path, { external: true })
    }
  }

  // Ignore unknown API routes for routing
  // IF route starts with /{locale}/api
  const regexPattern = /^\/([a-z]|-){2,5}\/api\//
  const knownApiRoutes = ['/api/exponea', '/api/bloomreach/page', '/api/bloomreach/document']
  if (regexPattern.test(to.path) && !knownApiRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored API route: ${to.path}`)
    return abortNavigation()
  }

  const ignoredRoutes = ['/vue/cart/sync', '/sw.js', '_nuxt/node_modules']
  if (ignoredRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored route: ${to.path}`)
    return abortNavigation()
  }

  // Deduce if a pretty URL is being used, if so make sure the renderKey stays the same so the page doesn't re-render
  if (to.path.includes('.html') && to.path.split('/').length < 4) {
    to.meta.key = to.path.replace('.html', '')
  } else if (to.path.includes('.html')) {
    const { prettyUrls } = storeToRefs(usePrettyUrlStore())

    const getEndPart = (path: string) => {
      return path
        .substring(path.lastIndexOf('/') + 1)
        .replace('.html', '')
        .split('-')
    }

    const getMatchedUrl = (endPart: string[]) => {
      return endPart.find((url) => prettyUrls.value.find((prettyUrl) => prettyUrl.slug === url))
    }

    const getRelativeUrl = (path: string) => {
      return path.substring(0, path.lastIndexOf('/'))
    }

    const toEndPart = getEndPart(to.path)
    const toMatchedPrettyUrl = getMatchedUrl(toEndPart)
    const toRelativeUrl = getRelativeUrl(to.path)
    const fromEndPart = getEndPart(from.path)
    const fromMatchedPrettyUrl = getMatchedUrl(fromEndPart)
    const fromRelativeUrl = getRelativeUrl(from.path)

    switch (true) {
      case toMatchedPrettyUrl && toRelativeUrl === fromRelativeUrl:
        logger.info('stay', { toEndPart, toRelativeUrl, fromRelativeUrl })
        to.meta.key = toRelativeUrl
        break

      case toMatchedPrettyUrl && toRelativeUrl === from.path.replace('.html', ''):
        logger.info('stay', { toRelativeUrl })
        to.meta.key = toRelativeUrl
        break

      case fromMatchedPrettyUrl && fromRelativeUrl === to.path.replace('.html', ''):
        logger.info('stay', { fromRelativeUrl })
        to.meta.key = fromRelativeUrl
        break

      default:
        logger.info('default, use regular route', to.path)
        to.meta.key = to.path.replace('.html', '')
    }
  }
})
