// https://vee-validate.logaretm.com/v4/guide/global-validators
import { defineRule } from 'vee-validate'

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()

  defineRule('required', (value: any): boolean | string => {
    const fieldValue = value?.toString().trim()
    if (!fieldValue || !fieldValue.length) {
      return $i18n.t('This field is required')
    }
    return true
  })

  defineRule('minLength', (value: any, [limit]: [number]) => {
    if (!value || !value.length) {
      return true
    }
    if (value.length < limit) {
      return $i18n.t(`The field should have at least {amount} characters`, { amount: limit })
    }
    return true
  })

  defineRule('maxLength', (value: any, [limit]: [number]) => {
    if (!value || !value.length) {
      return true
    }
    if (value.length > limit) {
      return $i18n.t(`Maximum characters`, { amount: limit })
    }
    return true
  })

  defineRule('email', (value: any): boolean | string => {
    if (!value || !value.length) {
      return true
    }

    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegExp.test(value)) {
      return $i18n.t('Invalid email')
    }
    return true
  })

  defineRule('phone', (value: any): boolean | string => {
    const localePrefixes = {
      'nl-nl': '(\\+31|\\+32)',
      fr: '(\\+32|\\+33)',
      de: '(\\+49|\\+43)',
      en: '(\\+[0-9]{1,2})*',
    }

    const regexpPrefix = new RegExp(`^${localePrefixes[$i18n.locale.value]}.*`, 'g')
    const regexpComplete = new RegExp(`^${localePrefixes[$i18n.locale.value]}[0-9]{6,}$`, 'g')

    if (!value || !value.length) {
      return true
    }

    if (value.length < 4) {
      return ' '
    }

    if (!regexpPrefix.test(value)) {
      return $i18n.t('Invalid prefix phone number')
    }

    if (!regexpComplete.test(value)) {
      return $i18n.t('Invalid phone number')
    }

    return true
  })

  defineRule('lettersOnly', (value: any): boolean | string => {
    if (!value || !value.length) {
      return true
    }

    const lettersRegExp = /^[a-zA-Z]+$/
    if (!lettersRegExp.test(value)) {
      return $i18n.t('Please only enter letters')
    }
    return true
  })
})
