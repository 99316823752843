/**
 * Store for managing A/B tests.
 *
 * This store is responsible for setting and retrieving A/B tests from Exponea,
 * keeping track of the tests that are running, and storing them in the state.
 *
 * @returns {Object} The store's state and methods.
 * @returns {Ref<Map<number, 'A' | 'B'>>} tests - Reactive state containing the A/B test results.
 * @returns {Function} getAbTest - Method to retrieve an A/B test and store its result.
 * @returns {Function} setAbTests - Method to set all A/B tests.
 */

import { defineStore } from 'pinia'
import { useExponeaApi } from '~/composables/useExponeaApi'

interface AbTest {
  enabled: boolean
  locale: 'nl-nl' | 'en' | 'de' | 'fr'
  id: string
  name: string
  number: number
  variants: {
    [key: string]: {
      name: string
      percentage: number
    }
  }
}

/**
 * List of all A/B tests
 * All tests are stored in this array.
 */
const abTests: AbTest[] = [
  {
    enabled: false,
    locale: 'nl-nl',
    id: '67112701f0a2f98a65aa872a',
    name: 'A/B - PDP - Change button color/text when product is in shopping cart - NL #24025',
    number: 24025,
    variants: {
      v0: { name: 'ab-24025-default-atc-button-text', percentage: 50 },
      v1: { name: 'ab-24025-change-atc-button-text', percentage: 50 },
    },
  },
]

declare global {
  interface Window {
    hj: any
    exponea: any
  }
}

export const useAbTestStore = defineStore('abTest', () => {
  const { waitUntilExponeaCookieIsSet } = useExponeaStore()
  const { post } = useExponeaApi()
  const logger = useAppLogger('useAbTestStore')

  /*
   * Tests are stored in a map where the number is the test number and the version will be v0, v1, etc.
   * So for example: tests.get(24025) will return 'v0' or 'v1'
   */
  const state = reactive({
    tests: new Map<number, string>(),
  })

  /**
   * Sets all A/B tests upon initialization of the app
   * They'll get loaded as quick as possible and are stored in the state
   */
  const setAbTests = async () => {
    const {
      $i18n: { locale },
    } = useNuxtApp()
    await waitUntilExponeaCookieIsSet()
    abTests
      .filter((test) => test.enabled && test.locale === locale.value)
      .forEach((test) => {
        getAbTest(test)
      })
  }

  /**
   * Retrieves and processes an A/B test result using the Exponea service.
   *
   * @param {Object} params - Parameters for the A/B test.
   * @param {string} params.id - Unique identifier for the test.
   * @param {number} params.number - Test number. We use this number to retrieve the test from the state.
   * @param {string} params.name - Name of the test.
   * @param {Object} params.variants - Variants of the test.
   * @param {Object} params.variants.v0 - First variant of the test.
   * @param {string} params.variants.v0.name - Name of the first variant.
   * @param {number} params.variants.v0.percentage - Percentage allocation for the first variant.
   * @param {Object} params.variants.v1 - Second variant of the test.
   * @param {string} params.variants.v1.name - Name of the second variant.
   * @param {number} params.variants.v1.percentage - Percentage allocation for the second variant.
   *
   * @returns {void} - But it will store result 'v0', 'v1', etc or 'ControlGroup' in the state.
   */
  const getAbTest = ({ number, name, variants }: AbTest): void => {
    window.exponea.getAbTest(
      name,
      {
        ...Object.fromEntries(Object.entries(variants).map(([key, value]) => [value.name, value.percentage])),
        ControlGroup: 0,
      },
      (variant: string) => {
        if (!variant) {
          logger.error('No variant found')
          return
        }
        state.tests.set(number, Object.entries(variants)?.find(([key, value]) => value.name === variant)?.[0] ?? 'v0')
      },
    )
  }

  /**
   * Sends an A/B test event to Exponea.
   *
   * @param {number} id - The unique identifier of the A/B test.
   * @param {'show' | 'click'} action - The action performed (e.g., 'show', 'click').
   */
  const sendAbTestEvent = (id: number, action: 'show' | 'click') => {
    const abVersion = state.tests.get(id)
    if (!abVersion) {
      logger.error(`Test with id ${id} not found in state`)
      return
    }

    const test = abTests.find((test) => test.number === id)
    if (!test) {
      logger.error(`Version ${abVersion} not found tests`)
      return
    }

    const cookie = useCookie('__exponea_etc__').value
    const variantName = test.variants[abVersion].name

    const exponeaData = {
      customer_ids: { cookie },
      properties: {
        action,
        banner_id: test.id,
        banner_name: test.name,
        banner_type: 'banner',
        variant_id: variantName.replaceAll(' ', '_').toLowerCase(),
        variant_name: variantName,
        interaction: false,
        location: window.location.href,
        path: window.location.pathname,
        variant_click: '',
      },
      event_type: 'banner',
    }

    if (action === 'show') {
      window.hj('tagRecording', [variantName])
      window.hj('trigger', variantName)
    }

    post('/customers/events', exponeaData, 'track')
  }

  return {
    ...toRefs(state),
    setAbTests,
    sendAbTestEvent,
  }
})

export default useAbTestStore
