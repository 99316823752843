// @todo: move to route watcher, because this one's too early
import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  async scrollBehavior(to, from, savedPosition) {
    const { hooks } = useNuxtApp()

    // Wait for the page to finish loading before scrolling
    await new Promise<void>((resolve) => {
      hooks.hook('page:finish', () => {
        resolve()
      })
    })

    const isCatalogOrSearchPage = to.path.includes('.html') || to.path.includes('/catalogsearch/result')

    // If going from a PDP back to a PCP or Search that has paginated we don't have the same amount of
    // products as we had before. So we have to find the product "manually" and scroll to it
    if (isCatalogOrSearchPage && !!to.query.p) {
      const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'))
      const scrollPosition = scrollPositions[to.fullPath]

      const element = document.querySelector(`.product-card[data-sku=${scrollPosition?.sku?.replace(' ', '')}]`)
      if (element) {
        return {
          behavior: 'instant',
          top:
            element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - scrollPosition.offsetTop,
        }
      }
    }

    // If no pagination, default scroll
    if (!to.query.p) {
      return {
        top: savedPosition?.top || 0,
        behavior: 'instant',
      }
    }

    // Else = load more, do nothing
    return false
  },
}
