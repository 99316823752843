import { default as indexEQ3KNsPU4lMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93_46htmle3MQ2ydb0sMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].html.vue?macro=true";
import { default as _91_46_46_46slug_93BsAb9KuvXKMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].vue?macro=true";
import { default as blogpostRPfNMqKg4lMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue?macro=true";
import { default as result5Bm1dqvEBDMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/catalogsearch/result.vue?macro=true";
import { default as indexRPAUZLVN0QMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/index.vue?macro=true";
import { default as loginJH5MCCNGvwMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/login.vue?macro=true";
import { default as register8P3rZehAmJMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/register.vue?macro=true";
import { default as customerservice4tJurfuFG9Meta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue?macro=true";
import { default as qualifio_45test_45pageaXv8DXL3U7Meta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/qualifio/qualifio-test-page.vue?macro=true";
import { default as shopskVawtNvryjMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue?macro=true";
import { default as wishlistIoJR6enO6uMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/wishlist.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue")
  },
  {
    name: "slug.html___nl-nl",
    path: "/nl-nl/:slug(.*)*.html",
    meta: { ...(_91_46_46_46slug_93_46htmle3MQ2ydb0sMeta || {}), ...{"middleware":["catalog","bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].html.vue")
  },
  {
    name: "slug.html___de",
    path: "/de/:slug(.*)*.html",
    meta: { ...(_91_46_46_46slug_93_46htmle3MQ2ydb0sMeta || {}), ...{"middleware":["catalog","bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].html.vue")
  },
  {
    name: "slug.html___en",
    path: "/en/:slug(.*)*.html",
    meta: { ...(_91_46_46_46slug_93_46htmle3MQ2ydb0sMeta || {}), ...{"middleware":["catalog","bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].html.vue")
  },
  {
    name: "slug.html___fr",
    path: "/fr/:slug(.*)*.html",
    meta: { ...(_91_46_46_46slug_93_46htmle3MQ2ydb0sMeta || {}), ...{"middleware":["catalog","bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].html.vue")
  },
  {
    name: "slug___nl-nl",
    path: "/nl-nl/:slug(.*)*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/[...slug].vue")
  },
  {
    name: "blogpost___nl-nl",
    path: "/nl-nl/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blogpost___de",
    path: "/de/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blogpost___en",
    path: "/en/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blogpost___fr",
    path: "/fr/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "catalogsearch-result___nl-nl",
    path: "/nl-nl/catalogsearch/result",
    meta: { ...(result5Bm1dqvEBDMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___de",
    path: "/de/catalogsearch/result",
    meta: { ...(result5Bm1dqvEBDMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___en",
    path: "/en/catalogsearch/result",
    meta: { ...(result5Bm1dqvEBDMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___fr",
    path: "/fr/catalogsearch/result",
    meta: { ...(result5Bm1dqvEBDMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/catalogsearch/result.vue")
  },
  {
    name: "customer-account___nl-nl",
    path: "/nl-nl/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/index.vue")
  },
  {
    name: "customer-account___de",
    path: "/de/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/index.vue")
  },
  {
    name: "customer-account___en",
    path: "/en/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/index.vue")
  },
  {
    name: "customer-account___fr",
    path: "/fr/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/index.vue")
  },
  {
    name: "customer-account-login___nl-nl",
    path: "/nl-nl/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/login.vue")
  },
  {
    name: "customer-account-login___de",
    path: "/de/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/login.vue")
  },
  {
    name: "customer-account-login___en",
    path: "/en/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/login.vue")
  },
  {
    name: "customer-account-login___fr",
    path: "/fr/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/login.vue")
  },
  {
    name: "customer-account-register___nl-nl",
    path: "/nl-nl/customer/account/register",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/register.vue")
  },
  {
    name: "customer-account-register___de",
    path: "/de/customer/account/register",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/register.vue")
  },
  {
    name: "customer-account-register___en",
    path: "/en/customer/account/register",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/register.vue")
  },
  {
    name: "customer-account-register___fr",
    path: "/fr/customer/account/register",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customer/account/register.vue")
  },
  {
    name: "customerservice___nl-nl",
    path: "/nl-nl/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice___de",
    path: "/de/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice___en",
    path: "/en/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice___fr",
    path: "/fr/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "index___nl-nl",
    path: "/nl-nl",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/index.vue")
  },
  {
    name: "qualifio-qualifio-test-page___nl-nl",
    path: "/nl-nl/qualifio/qualifio-test-page",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/qualifio/qualifio-test-page.vue")
  },
  {
    name: "qualifio-qualifio-test-page___de",
    path: "/de/qualifio/qualifio-test-page",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/qualifio/qualifio-test-page.vue")
  },
  {
    name: "qualifio-qualifio-test-page___en",
    path: "/en/qualifio/qualifio-test-page",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/qualifio/qualifio-test-page.vue")
  },
  {
    name: "qualifio-qualifio-test-page___fr",
    path: "/fr/qualifio/qualifio-test-page",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/qualifio/qualifio-test-page.vue")
  },
  {
    name: "shops___nl-nl",
    path: "/nl-nl/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops___de",
    path: "/de/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops___en",
    path: "/en/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops___fr",
    path: "/fr/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "wishlist___nl-nl",
    path: "/nl-nl/wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/wishlist.vue")
  },
  {
    name: "wishlist___de",
    path: "/de/wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/wishlist.vue")
  },
  {
    name: "wishlist___en",
    path: "/en/wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr",
    path: "/fr/wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/wishlist.vue")
  },
  {
    name: "blog-subpage___nl-nl",
    path: "/nl-nl/blog/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blog-subpage___de",
    path: "/de/blog/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blog-subpage___en",
    path: "/en/blog/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "blog-subpage___fr",
    path: "/fr/blog/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/blogpost.vue")
  },
  {
    name: "customerservice-subpage___nl-nl",
    path: "/nl-nl/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice-subpage___de",
    path: "/de/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice-subpage___en",
    path: "/en/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "customerservice-subpage___fr",
    path: "/fr/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/customerservice.vue")
  },
  {
    name: "shops-subpage___nl-nl",
    path: "/nl-nl/:service(boutiques|boutiquen)/:slug+",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops-subpage___de",
    path: "/de/:service(boutiques|boutiquen)/:slug+",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops-subpage___en",
    path: "/en/:service(boutiques|boutiquen)/:slug+",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  },
  {
    name: "shops-subpage___fr",
    path: "/fr/:service(boutiques|boutiquen)/:slug+",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/shops.vue")
  }
]