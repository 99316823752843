import { defineStore } from 'pinia'
import type { Product } from '@vue-storefront/magento-types'
import type { Attribute, AttributeOption } from './useProductStore'

import YoutubeVimeoUrlParser from '~/utils/YoutubeVimeoUrlParser'
import { getAttributeData } from './helpers/getAttributeData'

export const useProductStore = defineStore('product', () => {
  const logger = useAppLogger('Store/Bloomreach')
  const { transformImageUrlToSize, ImageSize } = useMagentoImage()

  const state = reactive({
    activeProductSku: <string | null>null,
    products: <{ [pid: number | string]: Product }>{},
    configurableOptions: <{ [pid: number]: any }>{},
    productCustomOptionsConfiguration: <any>{},
    attributes: <Attribute[]>[],
    giftcardHolder: <Product | null>null,
    media: <{ [pid: number]: [] }>{},
    loading: false,
  })

  const setProduct = async (product: Product) => {
    logger.info(`setProduct`, { name: product?.name })
    state.activeProductSku = product.sku || ''
    state.giftcardHolder = null

    product?.configurable_options?.sort((a, b) => a.position - b.position)

    state.products[product.sku] = product

    await setProductMedia()
    updateConfigurableOptions({})
  }

  const updateGiftcardConfiguration = (optionUid: string, variantUid: string) => {
    const attribute = state.products[state.activeProductSku]?.configurable_options.find(
      (option) => option.attribute_uid === optionUid,
    )
    if (attribute?.attribute_code !== 'giftcard_price') return

    const value = attribute.values.find((attribute) => attribute.uid === variantUid)
    const attributePrice = parseInt(value?.swatch_data?.value) || 0
    if (!attributePrice) return

    state.products[state.activeProductSku] = {
      ...state.products[state.activeProductSku],
      price_range: {
        ...state.products[state.activeProductSku]?.price_range,
        minimum_price: {
          regular_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
          final_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
        },
      },
    }
  }

  /**
   * Retrieves attributes data.
   * @returns A promise that resolves to void.
   * @throws If an error occurs while retrieving the attributes data.
   */
  const getAttributes = async (): Promise<void> => {
    try {
      state.attributes = await getAttributeData()
    } catch (err) {
      throw err
    }
  }

  /**
   * Retrieves the label of an attribute by its ID and type.
   * @param attributeId The ID of the attribute.
   * @param type The type of the attribute.
   * @returns The label of the attribute, or an empty string if not found.
   */
  const getAttributeById = (attributeId: string, type: string): string => {
    const attribute = state.attributes
      ?.find((attribute) => attribute.attribute_code === type)
      ?.attribute_options.find((option) => option.value === attributeId)
    return attribute?.label || ''
  }

  /**
   * Retrieves the attribute options for a given attribute string.
   * @param attributeString - The attribute string to retrieve options for.
   * @returns An array of AttributeOption objects or undefined if the attribute string is not found.
   */
  const getAttribute = (attributeString: string): AttributeOption[] | undefined => {
    const productAttributes = state.products[state.activeProductSku]?.[attributeString]?.split(',')
    if (!productAttributes) return
    return state.attributes
      ?.find((attribute) => attribute.attribute_code === attributeString)
      ?.attribute_options.filter((option) => productAttributes.includes(option.value))
  }

  const productCustomOptionsCanAddToCartHandler = (sku) => {
    if (!state.products[sku]?.options?.length) return true
    // return state.products[sku]?.options?.every((option) => state.productCustomOptionsConfiguration?.[option.uid]);
    // @todo: set to uid when MYJE-4300 is fixed
    return state.products[sku]?.options?.every((option) => state.productCustomOptionsConfiguration?.[option.option_id])
  }

  /**
   * Waits for the product to finish loading.
   * @returns A promise that resolves to true when the product is loaded.
   */
  const waitForProduct = async (sku: string) => {
    while (state.loading === true) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  /**
   * Sets the product media by filtering, sorting, and mapping the media gallery.
   * If a video is present, it normalizes the video URL, retrieves the video thumbnail,
   * and determines the video provider (either Vimeo or YouTube).
   * @returns {Promise<void>} A promise that resolves when the product media is set.
   */
  const setProductMedia = async (): Promise<void> => {
    try {
      state.media[state.activeProductSku] = await Promise.all(
        state.products[state.activeProductSku]?.media_gallery
          .filter((x) => !x.disabled)
          .sort((a, b) => a.position - b.position)
          // @ts-ignore - The type name and video_content should exist
          .map(async ({ url, label, video_content, position }) => {
            const asset = {
              url,
              alt: label,
              position,
            }

            if (video_content?.video_url) {
              const videoParser = new YoutubeVimeoUrlParser()
              // @ts-ignore - video should exist
              asset.video = {
                url: videoParser.getNormalizedSrc(video_content.video_url),
                title: video_content.video_title,
                provider: 'regular',
                id: videoParser.parseUrl(video_content.video_url).videoId,
                thumbnail: await videoParser.getVideoThumbnail(video_content.video_url),
              }

              if (video_content.video_url.includes('vimeo')) {
                // @ts-ignore
                asset.video.provider = 'vimeo'
              } else if (video_content.video_url.includes('youtube')) {
                // @ts-ignore
                asset.video.provider = 'youtube'
              }
            }
            return asset
          }),
      )
    } catch (error) {
      logger.error('useProductStore/setProductMedia', error)
    }
  }

  /**
   * Updates the product media based on the selected product configuration.
   * It filters the variants based on the selected attributes and updates the media accordingly.
   * If no variants are found, the media remains unchanged.
   */
  const updateProductMedia = (productConfiguration: Record<string, any>) => {
    const activeProductSku = state.activeProductSku
    if (!activeProductSku) return
    const productMedia = state.media[activeProductSku]
    const originalMediaLength = state.products[activeProductSku]?.media_gallery.filter((item) => !item.disabled).length
    const isClothingCategory = state.products[activeProductSku]?.categories[0]?.uid === 'MjQ='
    let variants: any = state.products[activeProductSku]?.variants

    Object.values(productConfiguration).forEach((value) => {
      if (!variants?.length || !value) return
      variants = variants?.filter((variant) => variant.attributes?.find((attribute) => attribute.uid === value))
    })

    const COLOR_UID = 'Mjkw'

    if (!variants?.length || !productMedia || !productConfiguration[COLOR_UID]) {
      return
    }

    // First time we add the variant image, next time we only replace it
    const mediaArray = productMedia.length > originalMediaLength ? productMedia?.slice(1) : productMedia
    const variantImage = {
      alt: variants?.[0]?.product.thumbnail?.label,
      url: transformImageUrlToSize(variants?.[0]?.product.thumbnail?.url, ImageSize.Original),
    }

    state.media[activeProductSku] = isClothingCategory ? [variantImage, ...mediaArray] : [variantImage, ...mediaArray]
  }

  /**
   * Updates the configurable options based on the current product configuration and stock status.
   */
  const updateConfigurableOptions = (productConfiguration: Record<string, any>) => {
    const productRef = state.products[state.activeProductSku]

    if (!productRef?.variants?.length) return

    state.configurableOptions[state.activeProductSku] = productRef.configurable_options.map((option, index) => {
      let options = productRef.variants
      for (let i = 0; i <= index - 1; i++) {
        const selectedUid = productConfiguration?.[productRef.configurable_options[i]?.attribute_uid]
        if (!selectedUid) continue
        options = options.filter((variant) => variant.attributes.find((attribute) => attribute.uid === selectedUid))
      }

      const values = option.values.map((value) => {
        const variants = options.filter((variant) =>
          variant.attributes.find((attribute) => attribute.uid === value.uid),
        )
        return {
          ...value,
          out_of_stock: variants.every((variant) => variant.product.stock_status === 'OUT_OF_STOCK')
            ? 'OUT_OF_STOCK'
            : 'IN_STOCK',
        }
      })

      return {
        ...option,
        uid: productConfiguration?.[option.attribute_uid],
        values,
      }
    })
  }

  return {
    ...toRefs(state),
    waitForProduct,
    setProduct,
    updateGiftcardConfiguration,
    updateConfigurableOptions,
    updateProductMedia,
    getAttributes,
    getAttribute,
    getAttributeById,
    productCustomOptionsCanAddToCartHandler,
  }
})

export default useProductStore
